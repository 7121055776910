<template>
<a-layout>
    <a-layout-content>
        <a-row class="fridaysearch">
            <div>
                <div class="s-search-bar1 " v-bind:class="[ _mobile  ? '' : 'pc' ]">
                    <a-col :span="span" :offset="offSet" style="height:100%">
                        <a-row style="height:100%;padding:10px" v-if="_mobile">
                            <a-col :span="cardSpan" class="s-search-bar2-m" v-bind:class="[ index==4  ? 'last' : '' ]" v-for="item in keywordList" :key="item.id">
                                <div class="title">
                                    <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}<br /><span class="subtitle">{{item.categoryName}}</span></span>

                                </div>
                                <div class="close" v-if="item.id > 0" @click="onRemoveKeyword(item.id)">
                                    <i class="fal fa-times"></i>
                                </div>
                            </a-col>

                            <a-col :span="cardSpan" class="s-search-bar2-m" style="cursor:pointer;border-bottom: 1px solid #dbdbdb;" v-if="index<4">
                                <div class="title2" v-if="!focus" @click="toggleFocus()">
                                    <i class="far fa-plus">&nbsp;&nbsp;</i> 검색어 추가
                                </div>
                                <div class="title2" v-else>
                                    <a-input v-model="keyword" @blur="toggleBlur()" @pressEnter="onAddNewKeyword()" placeholder="온라인쇼핑몰 키워드를 입력해주세요" style="width:100%">
                                    </a-input>
                                </div>
                            </a-col>
                        </a-row>

                        <a-row style="height:100%;" v-else>
                            <a-col :span="cardSpan" class="s-search-bar2" v-for="item in keywordList" :key="item.id">
                                <div class="title">
                                    {{item.no}}
                                    <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}<br /><span class="subtitle">{{item.categoryName}}</span></span>

                                </div>
                                <div class="close" v-if="item.id > 0" @click="onRemoveKeyword(item.id)">
                                    <i class="fal fa-times"></i>
                                </div>
                            </a-col>

                            <a-col :span="cardSpan" class="s-search-bar2" style="cursor:pointer" v-if="index<4">
                                <div class="title2" v-if="!focus" @click="toggleFocus()">
                                    <i class="far fa-plus">&nbsp;&nbsp;</i> 검색어 추가
                                </div>
                                <div class="title2" v-else>
                                    <a-input v-model="keyword" @blur="toggleBlur()" @pressEnter="onAddNewKeyword()" placeholder="온라인쇼핑몰 키워드를 입력해주세요" style="width:100%">
                                    </a-input>
                                </div>
                            </a-col>
                        </a-row>

                    </a-col>

                </div>
                <div class="s-search-bar3 " style="padding:10px">
                    <a-col :span="span" :offset="offSet">
                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">키워드 검색량 조회</span>
                            </a-col>
                        </a-row>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">총 검색량/등록제품수</span></div>
                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.pc+item.mobile).toLocaleString()}}건/{{parseInt(item.total).toLocaleString()}}개[{{(parseInt(item.pc+item.mobile)/parseInt(item.total)).toFixed(2)}}] </div>
                                </div>
                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;border-right:1px solid #dbdbdb">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.mobile).toLocaleString()}}건</div>
                                </div>

                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">PC</span></div>
                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.pc).toLocaleString()}}건</div>
                                </div>
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">키워드 상세조회</span>
                            </a-col>
                        </a-row>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">PC 검색량</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.pc).toLocaleString()}}건</div>
                                </div>

                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;border-right:1px solid #dbdbdb">
                                <div class="title-header"> <span class="title">남성</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.pc * (item.m / 100)).toLocaleString()}}건</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">여성</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.pc * (item.f / 100)).toLocaleString()}}건</div>

                                </div>
                            </a-col>

                        </a-row>

                        <a-row class="s-search-bar4" style="height:100%;margin-top:20px">
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">모바일 검색량</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.mobile).toLocaleString()}}건</div>
                                </div>

                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;border-right:1px solid #dbdbdb">
                                <div class="title-header"> <span class="title">남성</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.mobile * (item.m / 100)).toLocaleString()}}건</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">여성</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div class="content2">{{parseInt(item.mobile * (item.f / 100)).toLocaleString()}}건</div>
                                </div>
                            </a-col>

                        </a-row>

                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">연관 검색어</span>
                            </a-col>
                        </a-row>

                        <a-tabs :default-active-key="0">
                            <a-tab-pane v-for="item in keywordList" :key="item.id">
                                <span slot="tab" style='display:flex'>
                                    <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                </span>

                                <a-row class="s-search-bar4" style="height:100%" v-if="!_mobile">
                                    <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.list" :key="sitem" v-if="sitem.no >0 && sitem.no <=5" v-bind:class="{ start: sitem.no==1, end : sitem.no ==5 }">
                                            <span class="no">{{sitem.no}}</span>&nbsp;&nbsp;<span class="info">{{sitem.name}}&nbsp;{{parseInt(sitem.total).toLocaleString()}}</span>
                                        </div>
                                    </a-col>
                                    <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.list" :key="sitem" v-if="sitem.no >5 && sitem.no <=10" v-bind:class="{ start: sitem.no==6, end : sitem.no ==10 }">
                                            <span class="no">{{sitem.no}}</span>&nbsp;&nbsp;<span class="info">{{sitem.name}}&nbsp;{{parseInt(sitem.total).toLocaleString()}}</span>
                                        </div>
                                    </a-col>
                                    <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.list" :key="sitem" v-if="sitem.no >10 && sitem.no <=15" v-bind:class="{ start: sitem.no==11, end : sitem.no ==15 }">
                                            <span class="no">{{sitem.no}}</span>&nbsp;&nbsp;<span class="info">{{sitem.name}}&nbsp;{{parseInt(sitem.total).toLocaleString()}}</span>
                                        </div>
                                    </a-col>
                                </a-row>
                                <a-row class="s-search-bar4" style="height:100%" v-else>
                                    <a-col :span="24" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.list" :key="sitem" v-if="sitem.no <=15" v-bind:class="{ start: sitem.no==1, end : sitem.no ==15 }">
                                            <span class="no">{{sitem.no}}</span>&nbsp;&nbsp;<span class="info">{{sitem.name}}&nbsp;{{parseInt(sitem.total).toLocaleString()}}</span>
                                        </div>
                                    </a-col>
                                </a-row>

                            </a-tab-pane>
                        </a-tabs>

                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">연령별 순위</span>
                            </a-col>
                        </a-row>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">1위</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.listage[0]" class="content2">{{item.listage[0].label}}</div>
                                </div>

                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;border-right:1px solid #dbdbdb">
                                <div class="title-header"> <span class="title">2위</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.listage[1]" class="content2">{{item.listage[1].label}}</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile8" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">3위</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.listage[2]" class="content2">{{item.listage[2].label}}</div>

                                </div>
                            </a-col>

                        </a-row>
                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">상위노출 가능 블로거 조회</span>
                            </a-col>
                        </a-row>

                        <a-tabs :default-active-key="0">
                            <a-tab-pane v-for="item in keywordList" :key="item.id">
                                <span slot="tab" style='display:flex'>
                                    <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                </span>

                                <a-row class="s-search-bar4" style="height:100%" v-if="!_mobile">
                                    <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.blog_p" :key="sitem" v-if="sitem.no <=5" v-bind:class="{ start: sitem.no==1, end : sitem.no ==5 }">
                                            <span class="no"><img :src="sitem.img" style="width:30px;height:30px;border-radius:50%;margin-right:5px" />{{sitem.no}}</span>&nbsp;&nbsp;<span class="info" style="color:#505050">{{sitem.naverId}} <br/> <a :href="sitem.href" target="_blank">{{sitem.title}}</a></span>
                                            <div><span  class="info">{{sitem.contents}}</span></div>
                                        </div>

                                    </a-col>
                                    <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.blog_p" :key="sitem" v-if="sitem.no >5" v-bind:class="{ start: sitem.no==6, end : sitem.no ==10 }">
                                              <span class="no"><img :src="sitem.img" style="width:30px;height:30px;border-radius:50%;margin-right:5px" />{{sitem.no}}</span>&nbsp;&nbsp;<span class="info" style="color:#505050">{{sitem.naverId}} <br/> <a :href="sitem.href" target="_blank">{{sitem.title}}</a></span>
                                            <div><span  class="info">{{sitem.contents}}</span></div>
                                        </div>

                                    </a-col>

                                </a-row>
                                <a-row class="s-search-bar4" style="height:100%" v-else>
                                    <a-col :span="24" class="s-search-bar5 " style="height:100%">
                                        <div class="box" v-for="sitem in item.blog_p" :key="sitem" v-bind:class="{ start: sitem.no==1, end : sitem.no ==10 }">
                                             <span class="no"><img :src="sitem.img" style="width:30px;height:30px;border-radius:50%;margin-right:5px" />{{sitem.no}}</span>&nbsp;&nbsp;<span class="info" style="color:#505050">{{sitem.naverId}} <br/> <a :href="sitem.href" target="_blank">{{sitem.title}}</a></span>
                                            <div><span  class="info">{{sitem.contents}}</span></div>
                                        </div>
                                    </a-col>
                                </a-row>

                            </a-tab-pane>
                        </a-tabs>

                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">네이버 파워링크 단가</span>
                            </a-col>
                        </a-row>
                        <div class="s-search-font2">1위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.power_p_bid[0]" class="content2"><span v-if="parseInt(item.power_p_bid[0].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_p_bid[0].bid).toLocaleString()}}원</span></div>
                                </div>

                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">

                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.power_m_bid[0]" class="content2"><span v-if="parseInt(item.power_m_bid[0].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_m_bid[0].bid).toLocaleString()}}원</span></div>
                                </div>

                            </a-col>
                        </a-row>
                        <div class="s-search-font2" style="margin-top:20px">2위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>

                                    <div v-if="item.power_p_bid[1]" class="content2"><span v-if="parseInt(item.power_p_bid[1].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_p_bid[1].bid).toLocaleString()}}원</span></div>
                                </div>

                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>

                                    <div v-if="item.power_m_bid[1]" class="content2"><span v-if="parseInt(item.power_m_bid[1].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_m_bid[1].bid).toLocaleString()}}원</span></div>
                                </div>
                            </a-col>
                        </a-row>
                        <div class="s-search-font2" style="margin-top:20px">3위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>

                                    <div v-if="item.power_p_bid[2]" class="content2"><span v-if="parseInt(item.power_p_bid[2].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_p_bid[2].bid).toLocaleString()}}원</span></div>
                                </div>

                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>

                                    <div v-if="item.power_m_bid[2]" class="content2"><span v-if="parseInt(item.power_m_bid[2].bid)==70">조회불가</span><span v-else>{{parseInt(item.power_m_bid[2].bid).toLocaleString()}}원</span></div>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24" style="margin-top:60px;margin-bottom:20px">
                                <span class="s-search-font1">네이버 쇼핑검색 단가</span>
                            </a-col>
                        </a-row>
                        <div class="s-search-font2">1위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">

                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_p_bid[0]" class="content2">{{parseInt(item.shopping_p_bid[0].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_m_bid[0]" class="content2">{{parseInt(item.shopping_m_bid[0].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                        </a-row>
                        <div class="s-search-font2" style="margin-top:20px">2위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_p_bid[1]" class="content2">{{parseInt(item.shopping_p_bid[1].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_m_bid[1]" class="content2">{{parseInt(item.shopping_m_bid[1].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                        </a-row>
                        <div class="s-search-font2" style="margin-top:20px">3위</div>
                        <a-row class="s-search-bar4" style="height:100%">
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%">
                                <div class="title-header"> <span class="title">PC</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_p_bid[2]" class="content2">{{parseInt(item.shopping_p_bid[2].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                            <a-col :span="quartile12" class="s-search-bar5 " style="height:100%;border-left:1px solid #dbdbdb;">
                                <div class="title-header"> <span class="title">모바일</span></div>

                                <div v-for="item in keywordList" :key="item.id" style="display:flex">
                                    <div class="content1">
                                        <div class="dot" v-bind:class="{ dot0: item.id==0, dot1 : item.id ==1 , dot2 : item.id ==2, dot3 : item.id ==3  }"></div><span style="padding-left:5px">{{item.keyword}}</span>
                                    </div>
                                    <div v-if="item.shopping_m_bid[2]" class="content2">{{parseInt(item.shopping_m_bid[2].bid).toLocaleString()}}원</div>
                                </div>
                            </a-col>
                        </a-row>
                    </a-col>

                </div>
            </div>
        </a-row>
    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
import {
  getLicense,
  replaceAll,
  getCurrentTime,
  getCurrentTime2,
  getCurrentTimeNew,
  isNaN,
  formatNumber,
  getSR,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";

export default {
  components: {
    NavFooter
  },
  data() {
    return {
      offSet: 4,
      span: 16,
      keyword: "",
      focus: false,
      index: 1,
      cardSpan: 12,
      keywordList: [],
      quartile8: 8,
      quartile12: 12
    };
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();

      this.span = _mobile ? 24 : 16;
      this.offSet = _mobile ? 0 : 4;
      this.quartile8 = _mobile ? 24 : 8;
      this.quartile12 = _mobile ? 24 : 12;
      this.cardSpan = _mobile ? 24 : 12;

      return _mobile;
    }
  },
  mounted() {
    this.keywordList = [];
    this.keywordList.push({
      id: 0,
      keyword: this.$route.query.cid,
      categoryName: "",
      list: [],
      pc: 0,
      mobile: 0,
      total: 0,
      f: 0,
      m: 0,
      power_p_bid: [],
      power_m_bid: [],
      shopping_p_bid: [],
      shopping_m_bid: [],
      listage: [],
      blog_p: [],
      blog_m: []
    });

    this.onLoadApi(replaceAll(this.keywordList[0].keyword, " ", ""), 0);
  },
  methods: {
    onLoadApi(_keywordname, _index) {
      var _keywordname_ = _keywordname.toUpperCase();
      this.$vs.loading();
      this.onGetCategory(_keywordname_, _index);
      this.onGetKeyword(_keywordname_, _index);
      this.onGetPownerPricePc(_keywordname_, _index);
      this.onGetPownerPriceMobile(_keywordname_, _index);
      this.onGetShopingPricePc(_keywordname_, _index);
      this.onGetShopingPriceMobile(_keywordname_, _index);
      this.getPcBlogList(_keywordname_, _index);
      //   this.getMobileBlogList(_keywordname, _index);
      this.onSaveNaverKeywordSnapshot(_keywordname);
      document.getElementById("fullroot").scrollTop = 0;
      this.$vs.loading.close();
    },
    onSaveNaverKeywordSnapshot(_keywordname) {
      var _key = encodeURIComponent(_keywordname);
      var db = firebase.firestore();
      var self = this;

      var sfDocRef = db.collection("storelink_naver_keyword").doc(_key);

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            if (!sfDoc.exists) {
              db
                .collection("storelink_naver_keyword")
                .doc(_key)
                .set(
                  {
                    keyword: _keywordname,
                    eventtime: new Date(),
                    population: 1
                  },
                  {
                    merge: true
                  }
                )
                .then(function() {});
            } else {
              var newPopulation = sfDoc.data().population + 1;
              transaction.update(sfDocRef, {
                population: newPopulation,
                eventtime: new Date()
              });
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch(error => {
          console.log("Transaction failed: ", error);
        });
    },
    onGetCategory(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getCategory",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            // console.log("::onGetCategory", r.data.data);
            self.keywordList[_index].categoryName = r.data.data.category;
            self.keywordList[_index].total = r.data.data.productqty;
            self.onGetKeywordDetail1(_keywordname, r.data.data.code, _index);
            self.onGetKeywordDetail2(_keywordname, r.data.data.code, _index);
          }
        });
    },
    onGetKeyword(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getSearchKeyword",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            // console.log("::onGetKeyword", r.data.data);

            var _dummy = r.data.data.list;

            var _idx = 1;

            self.keywordList[_index].list = _dummy.filter(item => {
              if (item.name == _keywordname) {
                self.keywordList[_index].pc = item.pc;
                self.keywordList[_index].mobile = item.mobile;
              } else {
                item["no"] = _idx;
                _idx++;
                return item;
              }
            });
          }
        });
    },
    onGetKeywordDetail1(_keywordname, _code, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getSearchKeywordDetail1",
          {
            keywordname: _keywordname,
            code: _code
          }
        )
        .then(function(r) {
          // console.log("::onGetKeywordDetail1", r.data.data);
          self.keywordList[_index].f = r.data.data.f;
          self.keywordList[_index].m = r.data.data.m;
        });
    },
    onGetKeywordDetail2(_keywordname, _code, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getSearchKeywordDetail2",
          {
            keywordname: _keywordname,
            code: _code
          }
        )
        .then(function(r) {
          self.keywordList[_index].listage = r.data.data.listage;
          self.$vs.loading.close();
          //do workinng
        });
    },

    onGetPownerPricePc(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getPowerPricePc",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            self.keywordList[_index].power_p_bid = r.data.data;
            // console.log("::onGetPownerPricePc", r.data.data);
          }
        });
    },
    onGetPownerPriceMobile(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getPowerPriceMobile",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            self.keywordList[_index].power_m_bid = r.data.data;
            // console.log("::onGetPownerPriceMobile", r.data.data);
          }
        });
    },
    getPcBlogList(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getPcBlogList",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          console.log(r.data);
          if (r.data.status == "success") {
            self.keywordList[_index].blog_p = r.data.data;
          }
        });
    },
    getMobileBlogList(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getMobileBlogList",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            self.keywordList[_index].blog_m = r.data.data;
          }
        });
    },
    onGetShopingPricePc(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getShopinngPricePc",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            self.keywordList[_index].shopping_p_bid = r.data.data;
            // console.log("::onGetShopingPricePc", r.data.data);
          }
        });
    },
    onGetShopingPriceMobile(_keywordname, _index) {
      var self = this;
      this.$http
        .post(
          "https://us-central1-friday-fea9d.cloudfunctions.net/getShopingPriceMobile",
          {
            keywordname: _keywordname
          }
        )
        .then(function(r) {
          if (r.data.status == "success") {
            self.keywordList[_index].shopping_m_bid = r.data.data;
            // console.log("::onGetShopingPriceMobile", r.data.data);
          }
        });
    },

    onWait() {
      showError({
        notify: this.$vs.notify,
        msg: "아직 준비중입니다."
      });
    },
    onAddNewKeyword() {
      var _no = this.keywordList.length;
      var _key = new Date().getTime();

      this.keywordList.push({
        id: _no,
        keyword: this.keyword,
        categoryName: "",
        list: [],
        pc: 0,
        mobile: 0,
        total: 0,
        f: 0,
        m: 0,
        power_p_bid: [],
        power_m_bid: [],
        shopping_p_bid: [],
        shopping_m_bid: [],
        listage: []
      });

      this.onLoadApi(replaceAll(this.keyword, " ", ""), _no);
      this.toggleBlur();
      if (this._mobile) {
        //skip
      } else {
        if (this.index < 3) {
          this.cardSpan = 24 / (_no + 2);
        } else {
          this.cardSpan = 24 / (_no + 1);
        }
      }
      this.index++;
      this.$vs.loading.close();
    },
    onRemoveKeyword(no) {
      this.keywordList = this.keywordList.filter(item => {
        if (item.id == no) {
          //skip
        } else {
          return item;
        }
      });

      this.index--;
      var _no = this.keywordList.length;

      if (this._mobile) {
        //skip
      } else {
        this.cardSpan = 24 / (_no + 1);
      }
    },
    toggleFocus() {
      this.focus = true;
    },
    toggleBlur() {
      this.focus = false;
      this.keyword = "";
    }
  }
};
</script>

<style>
.s-search-bar1 {
  background-color: #ffffff;
}

.s-search-bar1.pc {
  height: 150px;
  background-color: #ffffff;
}

.s-search-bar2-m {
  height: 80px;
  background-color: #ffffff;
  display: flex;
  border-top: 1px solid #dbdbdb;
  /* border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb; */
}

.s-search-bar2-m.last {
  border-bottom: 1px solid #dbdbdb;
}

.s-search-bar2-m .title {
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-search-bar2-m .title2 {
  padding-left: 20px;
  padding-top: 25px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
  width: 95%;
}

.s-search-bar2-m .close {
  position: absolute;
  right: 0;
  float: right;
  padding-right: 20px;
  padding-top: 20px;
  cursor: pointer;
}

.s-search-bar2-m .close i {
  color: rgba(0, 0, 0, 0.25);
  font-size: 15px;
}

.s-search-bar2 {
  /* border-right: 1px solid #dbdbdb; */
  height: 100%;
  display: flex;
}

.s-search-bar2 .title {
  padding-left: 20px;
  padding-top: 54px;
  display: flex;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-search-bar2 .title .subtitle,
.s-search-bar2-m .title .subtitle {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #7d7d7d;
}

.s-search-bar2 .title2 {
  padding-left: 20px;
  padding-top: 63px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
  width: 95%;
}

.s-search-bar2 .close {
  position: absolute;
  right: 0;
  float: right;
  padding-right: 20px;
  padding-top: 20px;
  cursor: pointer;
}

.s-search-bar2 .close i {
  color: rgba(0, 0, 0, 0.25);
  font-size: 15px;
}

.s-search-bar4 {
  height: 121px;
  border-radius: 2px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.s-search-bar4 .title {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #000000;
}

.s-search-bar5 {
  padding: 23px;
}

.fridaysearch .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
}

.fridaysearch .dot.dot0 {
  background-color: #0264fb;
}

.fridaysearch .dot.dot1 {
  background-color: #f3a800;
}

.fridaysearch .dot.dot2 {
  background-color: #fa6400;
}

.fridaysearch .dot.dot3 {
  background-color: #6dd400;
}

.s-search-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-search-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.52px;
  color: #000000;
  margin-bottom: 10px;
}

.s-search-bar4 .title-header {
  margin-bottom: 20px;
}

.s-search-bar4 .content1 {
  /* width: 50%; */
  text-align: left;
  display: flex;
}

.s-search-bar4 .content2 {
  /* width: 50%; */
  text-align: right;
  float: right;
  position: absolute;
  right: 0;
  padding-right: 10px;
}

.s-search-bar4 .no {
  font-family: "Muli";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-search-bar4 .info {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #606060;
}

.s-search-bar4 .box {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dbdbdb;
}

.s-search-bar4 .box.start {
  padding-top: 0px;
}

.s-search-bar4 .box.end {
  padding-bottom: 0px;
  border-bottom: 0px solid white;
}
</style>
